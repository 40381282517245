import PropTypes from "prop-types";
import React from "react";

export const SuccessMsg = ({ msg = "" }) =>
    msg && <div className="text-ok">{msg}</div>;

export const ErrMsg = ({ msg = "" }) =>
    msg && <div className="text-err">{msg}</div>;

SuccessMsg.propTypes = {
    msg: PropTypes.string
};

ErrMsg.propTypes = {
    msg: PropTypes.string
};
