import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";

import Layout from "../../components/layout";
import Loading from "../../components/loading";
import { SuccessMsg, ErrMsg } from "../../components/message";
import { CurrentUserContext } from "../../providers/auth";
import Services, { hasAuthError } from "../../services";
import { checkPassword } from "../../utils/user";

const UserPasswordPage = () => {
    const [checkCount, setCheckCount] = useState(0);

    const forceCheckSession = () => {
        setCheckCount(checkCount + 1);
    };

    return (
        <Layout seoTitle="Update Password" forceCheckSessionCount={checkCount}>
            <UserPassword forceCheckSession={forceCheckSession} />
        </Layout>
    );
};

export default UserPasswordPage;

const UserPassword = ({ forceCheckSession }) => {
    const currentUser = useContext(CurrentUserContext);
    const userService = Services(currentUser).user;

    const [user, setUser] = useState(null);
    const [password, setPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [rPassword, setRPassword] = useState("");
    const [userErr, setUserErr] = useState({
        password: "",
        newPassword: "",
        rPassword: ""
    });
    const [updateErr, setUpdateErr] = useState("");
    const [updateOk, setUpdateOk] = useState(false);
    const [updating, setUpdating] = useState(false);

    const updatePassword = () => {
        setUpdateErr("");
        setUpdateOk(false);
        const errs = {
            password: password ? "" : "Passsword is required.",
            newPassword: newPassword
                ? checkPassword(newPassword)
                : "New password is required.",
            rPassword:
                newPassword && rPassword !== newPassword
                    ? "Wrong password confirmation."
                    : ""
        };
        setUserErr(errs);
        if (errs.password || errs.newPassword || errs.rPassword) {
            return;
        }

        setUpdating(true);
        userService
            .updateAccountPassword({
                password,
                newPassword
            })
            .then(() => {
                setUpdateOk(true);
                setPassword("");
                setNewPassword("");
                setRPassword("");
            })
            .catch((err) => {
                if (err.code && err.code === "INVALID_CREDENTIAL") {
                    setUserErr({ password: "Wrong current password" });
                } else {
                    if (hasAuthError(err)) {
                        forceCheckSession();
                    }
                    setUpdateErr(
                        `Update error${err.message ? ": " + err.message : ""}`
                    );
                }
            })
            .finally(() => setUpdating(false));
    };

    useEffect(() => {
        if (currentUser) {
            currentUser.getUser().then((user) => {
                setUser(user);
            });
        }
    }, [currentUser]);

    return user ? (
        <>
            <div className="title-h1">Update Password</div>
            <div className="flex w-3/4 mt-6">
                <div className="w-1/3">
                    <label className="label-r" htmlFor="password">
                        Current Password
                    </label>
                </div>
                <div className="w-2/3">
                    <input
                        className="input"
                        id="password"
                        type="password"
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                    ></input>
                    <ErrMsg msg={userErr.password} />
                </div>
            </div>
            <div className="flex w-3/4 mt-4">
                <div className="w-1/3">
                    <label className="label-r" htmlFor="new-password">
                        New Password
                    </label>
                </div>
                <div className="w-2/3">
                    <input
                        className="input"
                        id="new-password"
                        type="password"
                        title={checkPassword("")}
                        onChange={(e) => setNewPassword(e.target.value)}
                        value={newPassword}
                    ></input>
                    <ErrMsg msg={userErr.newPassword} />
                </div>
            </div>
            <div className="flex w-3/4 mt-4">
                <div className="w-1/3">
                    <label className="label-r" htmlFor="rpassword">
                        Password Confirmation
                    </label>
                </div>
                <div className="w-2/3">
                    <input
                        className="input"
                        id="rpassword"
                        type="password"
                        onChange={(e) => setRPassword(e.target.value)}
                        value={rPassword}
                    ></input>
                    <ErrMsg msg={userErr.rPassword} />
                </div>
            </div>

            <div className="flex w-3/4 mt-6">
                <div className="ml-auto w-2/3">
                    <button
                        className="btn btn-primary w-1/3 mr-4"
                        onClick={updatePassword}
                        disabled={updating}
                    >
                        Update
                    </button>
                    <ErrMsg msg={updateErr} />
                    <SuccessMsg
                        msg={
                            updateOk ? "Password is updated successfully." : ""
                        }
                    />
                </div>
            </div>
        </>
    ) : (
        <Loading />
    );
};

UserPassword.propTypes = {
    forceCheckSession: PropTypes.func.isRequired
};
